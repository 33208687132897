<template>
  <div id="loading" v-if="visibility">
    <div class="spinner"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Loader",
  computed: {
    ...mapGetters({
      visibility: "Loader/visibility",
    }),
  },
};
</script>

<style scoped>
/* ローディング画面 */
#loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 1s;
  background-color: #000;
  opacity: 0.2;
  z-index: 1000;
}
.spinner {
  width: 100px;
  height: 100px;
  margin: 45vh auto;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-scaleout 1s infinite ease-in-out;
}
/* ローディングアニメーション */
@keyframes sk-scaleout {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
