import Vue from "vue";
import Router from "vue-router";
import { useStore } from "@/store";
import { isMosaicMode, isSosenMode } from "@/appmode";

const NotFound = () => import("./views/NotFound");
const SosenStandalone = () => import("./views/sosen/StartStandalone");
const SosenTracking = () => import("./views/sosen/EditTracking");
const SosenAnnotation = () => import("./views/sosen/EditAnnotation");
const MosaicEdit = () => import("./views/mosaic/Edit");

const aliasIfMosaicMode = isMosaicMode ? { alias: "/" } : {};
const aliasIfSosenMode = isSosenMode ? { alias: "/" } : {};

Vue.use(Router);

const routes = [
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/sosen/standalone",
    name: "sosen-standalone",
    component: SosenStandalone,
    ...aliasIfSosenMode,
  },
  {
    path: "/sosen/tracking",
    name: "sosen-tracking",
    component: SosenTracking,
    beforeEnter: (to, from, next) => {
      const store = useStore();
      store.dispatch("UI/disableContentAreaScroll");
      store.dispatch("UI/setTitle", "ついせき");
      next();
    },
    props: true,
  },
  {
    path: "/sosen/annotation",
    name: "sosen-annotation",
    component: SosenAnnotation,
    beforeEnter: (to, from, next) => {
      const store = useStore();
      store.dispatch("UI/disableContentAreaScroll");
      store.dispatch("UI/setTitle", "アノテーション");
      next();
    },
    props: true,
  },
  {
    path: "/mosaic/edit",
    name: "mosaic-edit",
    component: MosaicEdit,
    beforeEnter: (to, from, next) => {
      const store = useStore();
      store.dispatch("UI/disableContentAreaScroll");
      store.dispatch("UI/setTitle", "モザイク処理");
      next();
    },
    ...aliasIfMosaicMode,
  },
];

const router = new Router({
  routes: routes,
});

export default router;
