import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const UI = {
  namespaced: true,
  state: {
    title: "QuaLap",
    navbarBackButtonStatus: false,
    contentAreaScrollStatus: true,
  },
  getters: {
    getNavbarTitle(state) {
      return state.title;
    },
    getNavbarBackButtonStatus(state) {
      return state.navbarBackButtonStatus;
    },
    getContentAreaScrollStatus(state) {
      return state.contentAreaScrollStatus;
    },
  },
  mutations: {
    updateTitle(state, title) {
      state.title = title;
    },
    updatecontentAreaScrollStatus(state, status) {
      state.contentAreaScrollStatus = status;
    },
  },
  actions: {
    setTitle({ commit }, title) {
      commit("updateTitle", title);
    },
    enableContentAreaScroll({ commit }) {
      commit("updatecontentAreaScrollStatus", true);
    },
    disableContentAreaScroll({ commit }) {
      commit("updatecontentAreaScrollStatus", false);
    },
  },
};

const AppSettings = {
  namespaced: true,
  state: {
    correctCount: 1000,
    trackingRadius: 8,
    circleAddRadius: 16,
    circleAddColorTracking: "#0000FF",
    circleAddColorAnnotation: "#00FF00",
    successfulCount: [],
  },
  getters: {
    correctCount(state) {
      return state.correctCount;
    },
    trackingRadius(state) {
      return state.trackingRadius;
    },
    circleAddRadius(state) {
      return state.circleAddRadius;
    },
    circleAddColorTracking(state) {
      return state.circleAddColorTracking;
    },
    circleAddColorAnnotation(state) {
      return state.circleAddColorAnnotation;
    },
    successfulCount(state) {
      return state.successfulCount;
    },
  },
  mutations: {
    updateCorrectCount(state, newState) {
      state.correctCount = newState;
    },
    updateTrackingRadius(state, newState) {
      state.trackingRadius = newState;
    },
    updateCircleAddRadius(state, newState) {
      state.circleAddRadius = newState;
    },
    updateCircleAddColorTracking(state, newState) {
      state.circleAddColorTracking = newState;
    },
    updateCircleAddColorAnnotation(state, newState) {
      state.circleAddColorAnnotation = newState;
    },
    updateSuccessfulCount(state, newState) {
      state.successfulCount = newState;
    },
  },
  actions: {
    setCorrectCount({ commit }, correctCount) {
      commit("updateCorrectCount", correctCount);
    },
    setTrackingRadius({ commit }, trackingRadius) {
      commit("updateTrackingRadius", trackingRadius);
    },
    setCircleAddRadius({ commit }, circleAddRadius) {
      commit("updateCircleAddRadius", circleAddRadius);
    },
    setCircleAddColorTracking({ commit }, circleAddColorTracking) {
      commit("updateCircleAddColorTracking", circleAddColorTracking);
    },
    setCircleAddColorAnnotation({ commit }, circleAddColorAnnotation) {
      commit("updateCircleAddColorAnnotation", circleAddColorAnnotation);
    },
    setSuccessfulCount({ commit }, successfulCount) {
      commit("updateSuccessfulCount", successfulCount);
    },
  },
};

const Loader = {
  namespaced: true,
  state: {
    visibility: false,
  },
  getters: {
    visibility(state) {
      return state.visibility;
    },
  },
  mutations: {
    updateVisibility(state, newState) {
      state.visibility = newState;
    },
  },
  actions: {
    show({ commit }) {
      commit("updateVisibility", true);
    },
    hide({ commit }) {
      commit("updateVisibility", false);
    },
  },
};

export const ProgressBar = {
  namespaced: true,
  state: {
    visibility: false,
    percentage: 0,
  },
  getters: {
    visibility(state) {
      return state.visibility;
    },
    percentage(state) {
      return state.percentage;
    },
  },
  mutations: {
    updateVisibility(state, newState) {
      state.visibility = newState;
    },
    updatePercentage(state, newState) {
      state.percentage = newState;
    },
  },
  actions: {
    show({ commit }) {
      commit("updateVisibility", true);
    },
    hide({ commit }) {
      commit("updateVisibility", false);
      commit("updatePercentage", 0);
    },
    set({ commit }, percentage) {
      commit("updatePercentage", percentage);
    },
  },
};

// Vuexインスタンス生成
const store = new Vuex.Store({
  modules: {
    UI,
    AppSettings,
    Loader,
    ProgressBar,
  },
});

export default store;
export const useStore = () => store;
