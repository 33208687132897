<template>
  <div v-if="visibility">
    <div id="background"></div>
    <div class="progressbar">
      <SimpleProgressBar
        size="medium"
        bar-color="#dc720f"
        text-fg-color="#dc720f"
        :val="percentage"
        :text="percentage + '%'"
      />
    </div>
  </div>
</template>

<script>
import SimpleProgressBar from "vue-simple-progress";
import { mapGetters } from "vuex";

export default {
  name: "ProgressBar",
  components: {
    SimpleProgressBar
  },
  computed: {
    ...mapGetters({
      visibility: "ProgressBar/visibility",
      percentage: "ProgressBar/percentage"
    })
  }
};
</script>

<style scoped>
/* ローディング画面 */
#background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 1s;
  background-color: #000;
  opacity: 0.2;
  z-index: 1000;
}
.progressbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 600px;
  height: 200px;
  margin: 45vh auto;
  z-index: 1000;
}
</style>
