import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueCompositionApi from "@vue/composition-api";
Vue.use(VueCompositionApi);

// custom styling
//import "bootstrap";
//import "bootstrap/dist/css/bootstrap.min.css";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
Vue.use(VueMaterial);

// a Vue Material Issue
// https://github.com/vuematerial/vue-material/issues/2285
Vue.component(
  "MdFile",
  Vue.options.components.MdFile.extend({
    methods: {
      isInvalidValue: function isInvalidValue() {
        return this.$el.validity
          ? this.$el.validity.badInput
          : this.$el.querySelector("input").validity.badInput;
      },
    },
  })
);

Vue.component(
  "MdSelect",
  Vue.options.components.MdSelect.extend({
    methods: {
      isInvalidValue: function isInvalidValue() {
        return this.$el.validity
          ? this.$el.validity.badInput
          : this.$el.querySelector("input").validity.badInput;
      },
    },
  })
);

Vue.config.productionTip = false;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA7-jl7JtwA_QuRUhU9SrS_DbPKJq9y8MA",
  authDomain: "mosaic-maker-ae112.firebaseapp.com",
  projectId: "mosaic-maker-ae112",
  storageBucket: "mosaic-maker-ae112.appspot.com",
  messagingSenderId: "667855642602",
  appId: "1:667855642602:web:e73363839406177d5b1c93",
};

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
